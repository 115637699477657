import { type RouteRecordRaw } from "vue-router";
import { renameNameRecursive } from "../helpers";
import { runRoutes } from "./runs";

export const patientRoutes: RouteRecordRaw[] = [
  {
    name: "Patients",
    path: "",
    component: async () => await import("../../views/PatientsList.vue"),
  },
  {
    name: "AddPatient",
    path: "add",
    component: async () => await import("../../views/AddPatient.vue"),
  },
  {
    name: "EditPatient",
    path: ":participant/edit",
    component: async () => await import("../../views/EditPatient.vue"),
  },
  {
    name: "ImportPatients",
    path: "import",
    component: async () => await import("../../views/ImportPatients.vue"),
  },
  {
    name: "DashboardParticipant",
    path: ":participant/DashboardParticipant",
    component: async () => await import("../../views/DashboardParticipant.vue"),
  },
  {
    name: "BilanV2",
    path: ":participant/bilanv2",
    component: async () => await import("../../views/BilanV2.vue"),
  },
  {
    name: "CreateFormPatient",
    path: ":participant/create-form",
    component: async () => await import("../../views/CreateForm.vue"),
    props: true,
  },
  {
    name: "ImportScorings",
    path: ":participant/import",
    component: async () =>
      await import("../../views/ImportPatientScorings.vue"),
  },
  {
    path: ":participant/run",
    children: renameNameRecursive("Run", runRoutes),
  },
];
