import { getUser } from "../api/users";
import { type Middleware } from "./middlewares";

// Middleware to require authentication
export const requireAuth: Middleware = async ({ authStore }) => {
  // If the user is not authenticated, log them out and redirect to the login page
  if (authStore.uuid === null) {
    await authStore.logout();
    return { type: "redirect", redirect: { name: "Login" } };
  }

  try {
    // Fetch the user data using the UUID from the auth store
    const user = await getUser(authStore.uuid);
    // Set the fetched user data in the auth store
    await authStore.setUser(user);
  } catch (e) {
    // If there is an error fetching the user, log the error, log the user out, and redirect to the login page
    console.error("Error fetching user", e);
    await authStore.logout();
    return { type: "redirect", redirect: { name: "Login" } };
  }

  // If everything is successful, proceed to the next middleware or route
  return { type: "next" };
};
